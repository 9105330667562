import React, { Fragment, useEffect, useRef, useState } from 'react'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import { useRouter } from 'next/router'
import { Row, Col, Button, Modal } from 'react-bootstrap'
import ScrollSlider from '../ScrollSlider'
import AppRating from '../AppRating'
import Faq from '../Faq'
import LeverageProfileBuilder from '../LeverageProfileBuilder'
import TeamUpSection from '../TeamUpSection'
import Review from '../Review'
import NewTestimonial from '../NewTestimonial'
import axios from 'axios'

import Image from 'next/image'
import LogoSlider from '../LogoSlider'
import moment from 'moment'
import {
  FormHelperText,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  Checkbox,
  ListItemText,
  FormControl,
  ListSubheader,
} from '@mui/material'
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input'

import styles from './modal.module.scss'
import Script from 'next/script'
import Autosuggest from 'react-autosuggest'
import {
  APIeventRegistrationNew,
  APIgetAllCitiesByName,
  APIgetCitiById,
  APIGetQuestionAnswer,
  APIMasterEventRegistration,
  APIrecordUserInteraction,
} from '../../config/API'
import { useSelector } from 'react-redux'
import { trackEventWithAuthStatus } from '../../utils/mixpanel'

const InterfaceId = 3
const index = ({ utm_source, utm_medium, utm_campaign, utm_content, utm_term, show, handleClose, eventDetails }) => {
  const router = useRouter()
  const redirect = () => {
    router.push('/contact')
  }

  const [formDetails, setFormDetails] = useState({
    fullName: '',
    lastName: '',
    firstName: '',
    lastName: '',
    email: '',
    grade: '',
    phone: '',
    school: '',
    userType: '',
    city: '',
    studentType: '',
    parentName: '',
    parentPhone: '',
    studentName: '',
    studentPhone: '',
    curriculum: '',
    countriesOfInterest: [],
    applyingCurrentYear: '',
  })
  const [showLinkModal, setShowLinkModal] = useState(false)
  const handleCloseLinkModal = () => setShowLinkModal(false)

  const [currentStep, setCurrentStep] = useState(1)

  const [isNextButtonDisable, setIsNextButtonDisable] = useState(false)
  const [isNext2ButtonDisable, setIsNext2ButtonDisable] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [suggestion, setSuggestion] = useState([])
  const [value, setValue] = useState('')
  const [cityName, setCityName] = useState('')
  const [allCities, setAllCities] = useState([])
  const getSuggestions = (value) => {
    setCityName(value)
  }

  const getSuggestionValue = (suggestion) => suggestion.name

  // Use your imagination to render suggestions.
  const renderSuggestion = (suggestion) => <div>{suggestion.name}</div>

  const onChangeHandler = (event, { newValue }) => {
    setValue(newValue)
  }

  const onSuggestionsFetchRequested = ({ value }) => {
    // setSuggestion(getSuggestions(value))
    getSuggestions(value)
  }

  const onSuggestionsClearRequested = () => {
    setSuggestion([])
  }

  const inputProps = {
    placeholder: 'Enter your city',
    value,
    onChange: onChangeHandler,
  }

  const findCityID = (value) => {
    return allCities.find((city) => {
      return city.name.toLowerCase() === value?.toLowerCase()
    })
  }

  const findCityName = async (value) => {
    const cityDetails = !allCities.length
      ? (await axios.get(APIgetCitiById + value)).data.city
      : allCities.find((city) => {
          return parseInt(city.id) === parseInt(value)
        })
    if (cityDetails !== null && cityDetails !== undefined) {
      setCityName(cityDetails.name)
      return cityDetails.name
    } else {
      return ''
    }
  }

  useEffect(() => {
    if (value) {
      setFormDetails({ ...formDetails, city: findCityID(value)?.id })
    }
  }, [value, allCities])

  useEffect(() => {
    const getAllCitiesByName = async () => {
      const result = await axios.get(APIgetAllCitiesByName + cityName)
      setAllCities(result.data.cities)
      const inputValue = cityName.trim().toLowerCase()
      const inputLength = inputValue.length

      const suggestions =
        inputLength === 0
          ? []
          : result.data.cities.filter((city) => city.name.toLowerCase().slice(0, inputLength) === inputValue)
      setSuggestion(suggestions)
    }

    setAllCities([])
    cityName && cityName.length >= 3 ? getAllCitiesByName() : setSuggestion([])
    // }, [formDetails.city])
  }, [cityName])

  const auth = useSelector((state) => state.auth)
  // console.log('Auth ----------->', auth)
  useEffect(() => {
    const getQuestionAnswer = async (userId) => {
      try {
        const result = await axios.get(`${APIGetQuestionAnswer}${userId}/1`)
        if (result.data.data) {
          setFormDetails((prev) => {
            return {
              ...prev,
              applyingCurrentYear: result.data.data?.ANSWER == 0 ? false : result.data.data?.ANSWER == 1 ? true : '',
            }
          })
        }
      } catch (err) {
        console.log('Error', err)
      }
    }
    if (auth?.userDetails?.ID) getQuestionAnswer(auth.userDetails?.ID)
  }, [auth.userDetails])
  useEffect(() => {
    const fetchData = async () => {
      if (auth.userDetails !== null && auth.userDetails !== undefined && auth.userDetails.ISPREMIUM !== undefined) {
        setFormDetails({
          ...formDetails,
          fullName:
            (auth.userDetails.FIRSTNAME !== undefined ? auth.userDetails?.FIRSTNAME : '') +
            (auth.userDetails.LASTNAME !== undefined ? ' ' + auth.userDetails?.LASTNAME : ''),
          firstName: auth.userDetails.FIRSTNAME !== undefined ? auth.userDetails?.FIRSTNAME : '',
          lastName: auth.userDetails.LASTNAME !== undefined ? auth.userDetails?.LASTNAME : '',
          // phoneNumber: auth.userDetails.PHONENUMBER,
          phone:
            auth.userDetails.PHONENUMBER && auth.userDetails.PHONENUMBER.includes('+')
              ? auth.userDetails.PHONENUMBER
              : auth.userDetails.COUNTRY_CODE.includes('+')
              ? `${auth.userDetails.COUNTRY_CODE}${auth.userDetails.PHONENUMBER}`
              : `+${auth.userDetails.COUNTRY_CODE}${auth.userDetails.PHONENUMBER}`,
          grade: auth.userDetails.GRADE
            ? [9, 12, 13, 14, 15].includes(+auth.userDetails.GRADE)
              ? +auth.userDetails.GRADE
              : ''
            : '',
          curriculum: curriculum.find((item) => item.value == auth.userDetails.CURRICULUM)?.value || '',
          email: auth.user ? auth.user : '',
          school: auth.userDetails?.SCHOOL,
          city: parseInt(auth.userDetails?.CITY),
          lastName: auth.userDetails.LASTNAME !== undefined ? auth.userDetails?.LASTNAME : '',
          studentType: auth.userDetails?.MS_USER_TYPE || '',
          userType: auth.userDetails.IS_PARENT ? 'parent' : 'student',
          lastName2: auth.userDetails.LASTNAME2 ? `${auth.userDetails?.LASTNAME2}` : '',
          parentName: auth.userDetails.IS_PARENT
            ? auth.userDetails.FIRSTNAME2 !== undefined
              ? `${auth.userDetails?.FIRSTNAME2}`
              : ''
            : '',
          parentPhone: auth.userDetails.IS_PARENT ? auth.userDetails.PHONENUMBER2 : '',
          studentName: !auth.userDetails.IS_PARENT
            ? auth.userDetails.FIRSTNAME2 !== undefined
              ? `${auth.userDetails?.FIRSTNAME2}`
              : ''
            : '',
          studentPhone: !auth.userDetails.IS_PARENT ? auth.userDetails.PHONENUMBER2 : '',
          userType: auth.isParent !== undefined ? (auth.isParent ? 'parent' : 'student') : '',
          countriesOfInterest: auth?.countries ? auth?.countries?.map((item) => String(item.TAG_ID)) : [],
        })
        // auth.user ? setEmailDisabled(true) : null

        const result = await findCityName(parseInt(auth.userDetails.CITY))
        setValue(result)
      }
    }

    fetchData()
  }, [auth])

  useEffect(() => {
    if (auth.userDetails !== null && auth.userDetails !== undefined && auth.userDetails.ISPREMIUM !== undefined) {
      if (formDetails.userType === 'student') {
        setFormDetails((prev) => {
          return {
            ...prev,
            parentName: auth.userDetails.FIRSTNAME2 !== undefined ? `${auth.userDetails?.FIRSTNAME2}` : '',
            parentPhone: auth.userDetails.PHONENUMBER2,
            studentName: '',
            studentPhone: '',
          }
        })
      } else {
        setFormDetails((prev) => {
          return {
            ...prev,
            studentName: auth.userDetails.FIRSTNAME2 !== undefined ? `${auth.userDetails?.FIRSTNAME2}` : '',
            studentPhone: auth.userDetails.PHONENUMBER2,
            parentName: '',
            parentPhone: '',
          }
        })
      }
    }
  }, [formDetails.userType])

  const isNullish = (target) => {
    for (var member in target) {
      if (
        target[member] == null ||
        target[member] == '' ||
        target[member] == undefined ||
        target[member] == [] ||
        (member === 'school' && target[member].length < 3)
      )
        return true
    }
    return false
  }

  function isValidEmail(email) {
    // A regular expression that matches the pattern of a valid email address
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailPattern.test(email)
  }

  const gradeRef = useRef(null)
  const typeRef = useRef(null)
  const emailRef = useRef(null)
  const phoneRef = useRef(null)
  const nameRef = useRef(null)
  const schoolRef = useRef(null)
  const cityRef = useRef(null)
  const buttonRef = useRef(null)
  const firstNameRef = useRef(null)
  const lastNameRef = useRef(null)
  const studentTypeRef = useRef(null)
  const currentApplyingYearRef = useRef(null)

  const updateRef = () => {
    if (!formDetails.studentType) {
      studentTypeRef.current.focus()
      studentTypeRef.current.closest('.col').scrollIntoView({ behavior: 'smooth' })
    } else if (!formDetails.grade) {
      gradeRef.current.focus()
      gradeRef.current.closest('.col').scrollIntoView({ behavior: 'smooth' })
    } else if (!formDetails.email || !isValidEmail(formDetails.email)) {
      emailRef.current.focus()
      emailRef.current.closest('.col').scrollIntoView({ behavior: 'smooth' })
    } else if (!formDetails.phone) {
      phoneRef.current.focus()
      phoneRef.current.closest('.col').scrollIntoView({ behavior: 'smooth' })
    }
  }

  const updateRef2 = () => {
    !formDetails.firstName
      ? (firstNameRef.current.focus(), firstNameRef.current.closest('.col').scrollIntoView({ behavior: 'smooth' }))
      : !formDetails.lastName
      ? (lastNameRef.current.focus(), lastNameRef.current.closest('.col').scrollIntoView({ behavior: 'smooth' }))
      : // : !formDetails.school
      // ? (schoolRef.current.focus(), schoolRef.current.closest('.col').scrollIntoView({ behavior: 'smooth' }))
      formDetails.city
      ? (cityRef.current.focus(), cityRef.current.closest('.col').scrollIntoView({ behavior: 'smooth' }))
      : formDetails.applyingCurrentYear !== true && formDetails.applyingCurrentYear !== false
      ? (currentApplyingYearRef.current.focus(),
        currentApplyingYearRef.current.closest('.col').scrollIntoView({ behavior: 'smooth' }))
      : null
  }

  const onNext = async () => {
    setIsNextButtonDisable(false)
    if (isValidEmail(formDetails.email) && isValidPhoneNumber(formDetails.phone)) {
      try {
        let userInteractionPayload = {
          email: formDetails.email,
          subjectId: eventDetails.ID,
          interactedAt: new Date(),
          interactionStage: 'button1',
          interactionType: 'onlineEvent',
          interfaceId: InterfaceId,
          shouldCreateNewUser: false,
          userDetails: {
            firstName: formDetails.firstName,
            lastName: formDetails.lastName,
            grade: formDetails.grade,
            countryCode: parsePhoneNumber(formDetails.phone).countryCallingCode,
            userType: formDetails.userType,
            phone: formDetails.phone,
            city: formDetails.city,
            schoolName: formDetails.school,
            platform: 'web',
          },
        }
        await axios.post(APIrecordUserInteraction, userInteractionPayload)
      } catch (e) {
        console.log(e)
      }
    }
    if (
      isNullish({
        grade: formDetails.grade,
        // userType: formDetails.userType,
        studentType: formDetails.studentType,
        email: formDetails.email,
        phone: formDetails.phone,
        // curriculum: formDetails.curriculum,
        // countriesOfInterest: formDetails.countriesOfInterest,
      }) ||
      !isValidPhoneNumber(formDetails.phone) ||
      !isValidEmail(formDetails.email)
      // false
    ) {
      updateRef()
      console.log('should not make it here', formDetails)
      setIsNextButtonDisable(true)
      return
    }
    setIsNextButtonDisable(false)
    setCurrentStep(2)
  }

  const onNext2 = async () => {
    setIsNext2ButtonDisable(false)

    try {
      let userInteractionPayload = {
        email: formDetails.email,
        subjectId: eventDetails.ID,
        interactedAt: new Date(),
        interactionStage: 'button2',
        interactionType: 'onlineEvent',
        interfaceId: InterfaceId,
        shouldCreateNewUser: false,
        userDetails: {
          firstName: formDetails.firstName,
          lastName: formDetails.lastName,
          grade: formDetails.grade,
          countryCode: parsePhoneNumber(formDetails.phone).countryCallingCode,
          userType: formDetails.userType,
          phone: formDetails.phone,
          city: formDetails.city,
          schoolName: formDetails.school,
          platform: 'web',
        },
      }

      await axios.post(APIrecordUserInteraction, userInteractionPayload)
    } catch (e) {
      console.log(e)
    }

    if (
      isNullish({
        city: formDetails.city,
        school: formDetails.school,
        firstName: formDetails.firstName,
        lastName: formDetails.lastName,
      }) ||
      (formDetails.applyingCurrentYear !== true && formDetails.applyingCurrentYear !== false)
    ) {
      updateRef2()
      console.log('should not make it here', formDetails)
      setIsNext2ButtonDisable(true)
      return
    }
    setIsNext2ButtonDisable(false)
    setCurrentStep(3)
  }

  const curriculum = [
    { value: '1', label: 'IGCSE' },
    { value: '2', label: 'IB' },
    { value: '3', label: 'CBSE' },
    { value: '4', label: 'ICSE' },
    { value: '5', label: 'STATE BOARD' },
    { value: '6', label: 'CAIE' },
    { value: '7', label: 'UNDERGRADUATE/ DEGREE' },
  ]

  const countries = [
    { value: '3', label: 'USA' },
    { value: '2', label: 'UK' },
    { value: '4', label: 'Canada' },
    // { value: '9', label: 'Australia/New Zealand' },
    { value: '6', label: 'Singapore' },
    // { value: '11', label: 'Hong Kong' },
    { value: '73', label: 'Other' },
  ]

  const onSubmitEventRegistration = async () => {
    try {
      setIsSubmitting(true)

      if (
        formDetails.email &&
        formDetails.grade &&
        formDetails.phone &&
        formDetails.city &&
        formDetails.firstName &&
        formDetails.lastName &&
        formDetails.studentType &&
        formDetails.applyingCurrentYear &&
        formDetails.school
        // &&
        // formDetails.curriculum
        // &&
        // formDetails.countriesOfInterest.length &&
        // formDetails.school.length >= 3
      ) {
        let userInteractionPayload = {
          email: formDetails.email,
          subjectId: eventDetails.ID,
          interactedAt: new Date(),
          interactionStage: 'registration',
          interactionType: 'onlineEvent',
          interfaceId: InterfaceId,
          shouldCreateNewUser: true,
          userDetails: {
            firstName: formDetails.firstName,
            lastName: formDetails.lastName,
            grade: formDetails.grade,
            countryCode: parsePhoneNumber(formDetails.phone).countryCallingCode,
            userType: formDetails.userType,
            phone: formDetails.phone,
            city: formDetails.city,
            schoolName: formDetails.school,
            platform: 'web',
          },
        }

        await axios.post(APIrecordUserInteraction, userInteractionPayload)

        let apiBody = {
          email: formDetails.email,
          eventID: eventDetails.ID,
          eventName: eventDetails.NAME,
          firstName: formDetails.firstName,
          lastName: formDetails.lastName,
          grade: formDetails.grade,
          countryCode: parsePhoneNumber(formDetails.phone).countryCallingCode,
          phone: formDetails.phone,
          city: formDetails.city,
          applyingCurrentYear: formDetails.applyingCurrentYear,
          studentType: formDetails.studentType,
          school_name: formDetails.school,
          // curriculum: formDetails.curriculum?.value,
          curriculum: formDetails.curriculum,
          // countriesOfInterest: formDetails.countriesOfInterest,
          // firstName2: formDetails.userType === 'student' ? formDetails.parentName : formDetails.studentName,
          lastName2: '',
          // userType: formDetails.userType,
          // phone2: formDetails.userType === 'student' ? formDetails.parentPhone : formDetails.studentPhone,
          ///////////////////////////
          utm_source,
          utm_medium,
          utm_campaign,
          utm_content,
          utm_term,
        }
        trackEventWithAuthStatus(auth, 'click_submit_event_reg_plus_page_web', {
          buttonId: 'online-event-regis-submit',
        })
        const registerEventDetails = await axios.post(
          // APIeventRegistrationNew,
          APIMasterEventRegistration,
          apiBody
        )
        console.log(registerEventDetails)
        if (registerEventDetails.data.statusCode === 200) {
          handleClose()

          router.push(`/event-registration-success/${eventDetails.ZOOMID}`)
        } else {
          setIsSubmitting(false)
        }
      } else {
        setIsSubmitting(false)
      }
    } catch (err) {
      console.log(err)
      if (err.response && err.response.data && err.response.data.statusCode && err.response.data.statusCode === 404) {
        setIsSubmitting(false)
      } else {
        setIsSubmitting(false)
      }
    }
  }

  console.log('FormDetails', formDetails)
  console.log('FormDetails', currentStep)

  const extractUniversityName = (text) => {
    if (text.toLowerCase().includes('ivy league')) {
      return 'Ivy League Admissions Workshop'
    }
    if (text.toLowerCase().includes('ib physics masterclass')) {
      return text
    }
    const commonWords = ['how', 'to', 'get', 'into', 'admissions', 'workshop', 'a']
    const words = text.toLowerCase().split(' ')

    // Filter out common words
    const filteredWords = words
      .filter((word) => !commonWords.includes(word.toLowerCase()))
      .map((item) => item[0].toUpperCase() + item.substring(1).toLowerCase())

    // Join the remaining words to get the university name
    return filteredWords.join(' ') + ' Admissions Workshop'
  }

  const universityName = eventDetails.NAME && extractUniversityName(eventDetails.NAME)

  const grades = {
    1: 8,
    2: 9,
    3: 10,
    4: 11,
    5: 12,
  }
  return (
    <Fragment>
      <Modal className="facebook-modal p-0" show={show} onHide={handleClose}>
        <Modal.Header closeButton style={{ backgroundImage: `url("${eventDetails.FORM_BANNER}")` }}></Modal.Header>

        {currentStep !== 1 && (
          <i
            class="fa fa-arrow-left facebook-modal-back-btn"
            aria-hidden="true"
            onClick={() => (currentStep === 2 ? setCurrentStep(1) : setCurrentStep(2))}
          ></i>
        )}
        <Modal.Body>
          {currentStep === 1 ? (
            <Row>
              <Col className="for-masters-only">
                <Row className="title-header-section">
                  <Col>
                    <Row className="facebook-holo">
                      <Col>
                        <Image src="/holo-gold.png" width="50px" height="50px" />
                        <p>CollegePass</p>
                      </Col>
                    </Row>
                    <Row className="facebook-title-desc">
                      <Col>
                        <p>
                          Exclusive {eventDetails.NAME}
                          {'! '}
                          {/* for{' '}
                          <span>
                            Grades{' '}
                            {eventDetails.GRADES
                              ? `${grades[Math.min(...eventDetails.GRADES.split(',').map((item) => +item))]} - ${
                                  grades[Math.max(...eventDetails.GRADES.split(',').map(Number))]
                                } `
                              : ''}
                          </span>
                          Students and Parents!  */}
                          Learn how to maximize your chances of getting accepted. Register now!
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row
                  className={
                    'student-parent-section' +
                    (isNextButtonDisable && !formDetails.studentType ? ' ' + styles.requiredField : '')
                  }
                >
                  <Col>
                    <Row>
                      <Col>
                        {isNextButtonDisable && !formDetails.studentType && (
                          <FormHelperText error>This response is required</FormHelperText>
                        )}
                        <p ref={studentTypeRef}>I'm a</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="student-parent-section-btn">
                        <Button
                          onClick={() => {
                            setFormDetails({
                              ...formDetails,
                              studentType: 'College Student',
                            })
                            // isNextButtonDisable && updateRef()
                            // updateRef()
                          }}
                          style={
                            formDetails.studentType === 'College Student'
                              ? {
                                  backgroundColor: '#c83232',
                                  color: '#fff',
                                }
                              : {}
                          }
                        >
                          College Student
                        </Button>
                        <Button
                          onClick={() =>
                            setFormDetails({
                              ...formDetails,
                              studentType: 'Working Professional',
                            })
                          }
                          style={
                            formDetails.studentType === 'Working Professional'
                              ? {
                                  backgroundColor: '#c83232',
                                  color: '#fff',
                                }
                              : {}
                          }
                        >
                          Working Professional
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row
                  className={
                    'student-parent-section' +
                    (isNextButtonDisable && !formDetails.grade ? ' ' + styles.requiredField : '')
                  }
                >
                  <Col>
                    <Row>
                      <Col>
                        {isNextButtonDisable && !formDetails.grade && (
                          <FormHelperText error>This response is required</FormHelperText>
                        )}
                        <p ref={gradeRef}>Qualification of Student</p>
                      </Col>
                    </Row>
                    <Row>
                      {/* <p className="mb-0">Qualification of Student</p> */}
                      <Col>
                        <FormControl fullWidth>
                          <InputLabel
                            id="grade-select"
                            shrink={false}
                            style={{
                              left: '-13px',
                            }}
                          >
                            {formDetails.grade ? '' : 'Select Qualification'}
                          </InputLabel>
                          <Select
                            id="standard-basic"
                            label="Standard"
                            variant="standard"
                            // placeholder="Select coi"
                            labelId="grade-select"
                            // multiple
                            value={formDetails.grade + ''}
                            onChange={(e) => {
                              console.log('Event details', e.target.value)
                              setFormDetails((prev) => {
                                return {
                                  ...prev,
                                  grade: +e.target.value,
                                }
                              })
                            }}
                            // renderValue={(selected) =>
                            //   selected
                            //     .map((item) => countries.find((i) => i.value === item)?.label)
                            //     .filter((item) => item)
                            //     .join(', ')
                            // }
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  maxHeight: 48 * 4.5 + 8,
                                  width: 250,
                                },
                              },
                            }}
                          >
                            <ListSubheader>In College</ListSubheader>
                            <MenuItem value="12">Year 1</MenuItem>
                            <MenuItem value="13">Year 2</MenuItem>
                            <MenuItem value="14">Year 3</MenuItem>
                            <MenuItem value="15">Year 4</MenuItem>

                            {/* Graduated Option */}
                            <MenuItem value="9">Graduated</MenuItem>
                          </Select>
                        </FormControl>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row
                  className={
                    'student-parent-section' +
                    (isNextButtonDisable && (!formDetails.email || !isValidEmail(formDetails.email))
                      ? ' ' + styles.requiredField
                      : '')
                  }
                >
                  <Col>
                    <p className="mb-2">Email</p>
                    <TextField
                      ref={emailRef}
                      id="standard-full-width"
                      label=""
                      placeholder="Enter your email"
                      variant="standard"
                      value={formDetails.email}
                      onChange={(e) =>
                        setFormDetails({
                          ...formDetails,
                          email: e.target.value,
                        })
                      }
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          phoneRef.current.focus()
                          e.preventDefault() // This prevents the default behavior of the "Enter" key in a form
                        }
                      }}
                    />
                    {isNextButtonDisable ? (
                      !formDetails.email || !isValidEmail(formDetails.email) ? (
                        <FormHelperText error>
                          {!formDetails.email ? 'This response is required' : 'Please enter valid email'}
                        </FormHelperText>
                      ) : null
                    ) : null}
                  </Col>
                </Row>
                <Row
                  className={
                    'student-parent-section' +
                    (isNextButtonDisable && (!formDetails.phone || !isValidPhoneNumber(formDetails.phone))
                      ? ' ' + styles.requiredField
                      : '')
                  }
                >
                  <Col>
                    <p>Phone Number</p>

                    <PhoneInput
                      ref={phoneRef}
                      // inputComponent={phoneInput}
                      className={styles.PhoneInput}
                      defaultCountry="IN"
                      placeholder={'Enter Phone Number'}
                      value={formDetails.phone}
                      onChange={(e) => setFormDetails({ ...formDetails, phone: e })}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          buttonRef.current.focus()
                          onNext()
                          e.preventDefault() // This prevents the default behavior of the "Enter" key in a form
                        }
                      }}
                    />
                    {isNextButtonDisable ? (
                      !formDetails.phone || !isValidPhoneNumber(formDetails.phone) ? (
                        <FormHelperText error>
                          {!formDetails.phone ? 'This response is required' : 'Please enter valid phone number'}
                        </FormHelperText>
                      ) : null
                    ) : null}
                  </Col>
                </Row>
              </Col>
            </Row>
          ) : currentStep === 2 ? (
            <Row>
              <Col className="for-masters-only">
                <Row className="facebook-form-contact-info">
                  <Col>
                    <Row>
                      <Col>
                        <h5>
                          Contact Information <i className="fa fa-info-circle" aria-hidden="true"></i>
                        </h5>
                        <p
                          style={{
                            marginTop: '10px',
                          }}
                        >
                          We'll use your information to contact you about our services.
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p className="mb-2">First Name</p>
                        <TextField
                          ref={firstNameRef}
                          id="standard-full-width"
                          label=""
                          placeholder="Enter your first name"
                          variant="standard"
                          value={formDetails.firstName}
                          onChange={(e) =>
                            setFormDetails({
                              ...formDetails,
                              firstName: e.target.value,
                              // firstName: e.target.value.includes(' ') ? e.target.value.split(' ')[0] : e.target.value,
                              // lastName: e.target.value.includes(' ') ? e.target.value.split(' ')[1] : '',
                            })
                          }
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              // const inputRef = schoolRef.current?.querySelector('input')
                              // inputRef && inputRef.focus()
                              lastNameRef.current.focus()
                              e.preventDefault() // This prevents the default behavior of the "Enter" key in a form
                            }
                          }}
                        />

                        {isNext2ButtonDisable ? (
                          !formDetails.firstName ? (
                            <FormHelperText error>
                              {!formDetails.firstName ? 'This response is required' : 'Please enter valid name'}
                            </FormHelperText>
                          ) : null
                        ) : null}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p className="mb-2">Last Name</p>
                        <TextField
                          ref={lastNameRef}
                          id="standard-full-width"
                          label=""
                          placeholder="Enter your name"
                          variant="standard"
                          value={formDetails.lastName}
                          onChange={(e) =>
                            setFormDetails({
                              ...formDetails,
                              lastName: e.target.value,
                              // lastName: e.target.value.includes(' ') ? e.target.value.split(' ')[0] : e.target.value,
                              // lastName: e.target.value.includes(' ') ? e.target.value.split(' ')[1] : '',
                            })
                          }
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              const inputRef = schoolRef.current
                              inputRef && inputRef.focus()
                              e.preventDefault() // This prevents the default behavior of the "Enter" key in a form
                            }
                          }}
                        />

                        {isNext2ButtonDisable ? (
                          !formDetails.lastName ? (
                            <FormHelperText error>
                              {!formDetails.lastName ? 'This response is required' : 'Please enter valid name'}
                            </FormHelperText>
                          ) : null
                        ) : null}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p className="mb-2">College/Company Name</p>
                        <TextField
                          ref={schoolRef}
                          id="standard-full-width"
                          label=""
                          placeholder="Enter college/company name"
                          variant="standard"
                          value={formDetails.school}
                          onChange={(e) =>
                            setFormDetails({
                              ...formDetails,
                              school: e.target.value,
                              // lastName: e.target.value.includes(' ') ? e.target.value.split(' ')[0] : e.target.value,
                              // lastName: e.target.value.includes(' ') ? e.target.value.split(' ')[1] : '',
                            })
                          }
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              const inputRef = cityRef.current
                              inputRef && inputRef.focus()
                              e.preventDefault() // This prevents the default behavior of the "Enter" key in a form
                            }
                          }}
                        />

                        {isNext2ButtonDisable ? (
                          !formDetails.school ? (
                            <FormHelperText error>
                              {!formDetails.school ? 'This response is required' : 'Please enter valid name'}
                            </FormHelperText>
                          ) : null
                        ) : null}
                      </Col>
                    </Row>

                    <Row>
                      <Col
                        className={styles.react_autosuggest__input}
                        // ref={cityRef}
                      >
                        <p className="mb-3">City</p>

                        <Autosuggest
                          suggestions={suggestion}
                          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                          onSuggestionsClearRequested={onSuggestionsClearRequested}
                          getSuggestionValue={getSuggestionValue}
                          renderSuggestion={renderSuggestion}
                          inputProps={{
                            ...inputProps,
                            ref: cityRef,
                            onKeyDown: (e) => {
                              if (e.key === 'Enter') {
                                // formDetails.city && onNext2()
                                currentApplyingYearRef.current.focus()
                                e.preventDefault() // This prevents the default behavior of the "Enter" key in a form
                              }
                            },
                          }}
                        />
                        {isNext2ButtonDisable && !formDetails.city && (
                          <FormHelperText error>This response is required</FormHelperText>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row
                  className={
                    'student-parent-section' +
                    (isNext2ButtonDisable &&
                    formDetails.applyingCurrentYear !== true &&
                    formDetails.applyingCurrentYear !== false
                      ? ' ' + styles.requiredField
                      : '')
                  }
                >
                  <Col>
                    <Row>
                      <Col>
                        {isNext2ButtonDisable &&
                          formDetails.applyingCurrentYear !== true &&
                          formDetails.applyingCurrentYear !== false && (
                            <FormHelperText error>This response is required</FormHelperText>
                          )}
                        <p ref={currentApplyingYearRef}>Are you applying for MS programs this year?</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="student-parent-section-grade-sec">
                        <Button
                          onClick={() =>
                            setFormDetails({
                              ...formDetails,
                              applyingCurrentYear: true,
                            })
                          }
                          style={
                            formDetails.applyingCurrentYear === true
                              ? {
                                  backgroundColor: '#c83232',
                                  color: '#fff',
                                }
                              : {}
                          }
                        >
                          Yes
                        </Button>
                        <Button
                          onClick={() =>
                            setFormDetails({
                              ...formDetails,
                              applyingCurrentYear: false,
                            })
                          }
                          style={
                            formDetails.applyingCurrentYear === false
                              ? {
                                  backgroundColor: '#c83232',
                                  color: '#fff',
                                }
                              : {}
                          }
                        >
                          No
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col className="for-masters-only">
                <Row className="facebook-form-privacy">
                  <Col>
                    <Row>
                      <Col>
                        <h5>Privacy policy</h5>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p>
                          By clicking Submit, you agree to send your information to CollegePass, who agrees to use it
                          according to their privacy & data policy. View{' '}
                          <a href="https://www.collegepass.org/privacy" target="_blank">
                            Privacy Policy.
                          </a>
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            id={currentStep === 3 ? 'online-event-regis-submit' : 'online-event-regis-next'}
            variant="secondary"
            ref={buttonRef}
            onClick={() => {
              //   setCurrentStep(2)
              currentStep === 1 ? onNext() : currentStep === 2 ? onNext2() : onSubmitEventRegistration()
            }}
          >
            {currentStep === 1 || currentStep === 2 ? 'Next' : 'Submit'}
          </Button>
        </Modal.Footer>
      </Modal>
      <NewTestimonial />
      <LogoSlider />
      <TeamUpSection />
      <LeverageProfileBuilder />
      <ScrollSlider />

      <AppRating />
      <Review />
      <Faq />

      {/*   Get link  */}

      <Modal
        show={showLinkModal}
        onHide={handleCloseLinkModal}
        backdrop="static"
        keyboard={false}
        className="get-link-cp"
      >
        <Modal.Header
          closeButton
          style={{
            borderBottom: '0',
          }}
        ></Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg={7} ms={7} sm={12} xs={12}>
              <Row>
                <Col>
                  <Row>
                    <Col>
                      <h1>
                        enter your phone number
                        <br /> to get the link via SMS
                      </h1>
                      <p>
                        alternatively, <a href="">scan QR code</a>
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <InputGroup
                        className="mb-3"
                        style={{
                          borderRadius: '30px',
                        }}
                      >
                        <Form.Control placeholder="+91 9876543210" aria-label="phone" aria-describedby="basic-addon2" />
                        <InputGroup.Text id="basic-addon2">
                          <Button>
                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                          </Button>
                        </InputGroup.Text>
                      </InputGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="d-flex pt-5">
                      <div>
                        <Image width="28" height="28" src="/play-store-logo.png" alt="play store icon" />
                      </div>
                      <div>
                        <Image width="28" height="28" src="/apple-store-logo.png" alt="apple icon" />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col lg={5} ms={5} sm={12} xs={12}>
              <Row>
                <Col>
                  <Image width="300" height="300" src="/download-qr.png" alt="apple icon" />
                </Col>
              </Row>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </Fragment>
  )
}

export default index
